import React from 'react'

import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import { Freebie } from '../../components/Freebie'

// App content
import fivethingsInvisalign from '../../assets/invis-5things.png'
import tenThingsCover from '../../assets/10Things3DCover.png'
import consultingStaff from '../../assets/mayo-landing/consulting-staff.png'
import alliedHealthStaff from '../../assets/mayo-landing/allied-health-staff.png'

// App utils
import { themeStyles, colors } from '../../utils/theme'

const matchPColor = { color: 'hsla(0,0%,0%,0.6)' }

const freebies = [
  {
    image: tenThingsCover,
    buttonText: 'Get Your FREE Report',
    link: '/resources/free/top-ten-things-you-must-know-before-choosing-your-familys-orthodontist',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Are you looking for a Family Friendly Orthodontist?
        </p>
        <h2 css={matchPColor}>
          Top 10 Things you Must Know Before Choosing Your Family’s Orthodontist
        </h2>
        <p>
          Choosing an orthodontist for you family can be stressful. You want to
          ensure a great fit across many vectors: Experience, Cost, Kindness,
          etc. How are you supposed to make this choice? We’ve come up with the
          top 10 things you must know before choosing your family’s
          orthodontist. We’d love to share them with you!
        </p>
      </div>
    ),
  },
  {
    image: consultingStaff,
    buttonText: 'Get Your FREE Report',
    link: '/mayo-insurance/mayo-physicians-consulting-and-voting-staff',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Are you a Mayo Physician, Consulting or Voting Staff?
        </p>
        <h2 css={matchPColor}>
          Your Entire Family is Eligible for 100% Orthodontic Coverage
        </h2>
        <p>
          If you are a Mayo Physician, Consulting or Voting Administrative
          Staff, then you and your entire family are eligible for{' '}
          <strong>100% orthodontic coverage</strong> at Collins Orthodontics.
          Essentially,{' '}
          <strong>
            Mayo Clinic will be covering the entire cost of treatment
          </strong>{' '}
          for you and your entire family. However, we do have some suggestions
          for you to make sure you end up paying{' '}
          <strong>nothing out-of-pocket</strong>, and we’ve put these
          suggestions in this free report.
        </p>
      </div>
    ),
  },
  {
    image: alliedHealthStaff,
    buttonText: 'Get Your FREE Report',
    link: '/mayo-insurance/mayo-allied-staff-residents-and-fellows',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Are you a Mayo Allied Staff Member, Resident or Fellow?
        </p>
        <h2 css={matchPColor}>
          You are Eligible for Extensive Orthodontic Benefits
        </h2>
        <p>
          If you are Allied Staff, then you are eligible to sign up for the
          following orthodontic plans with the following benefits:
        </p>
        <ul>
          <li>Standard Delta Dental: $1,500 Orthodontic Benefit</li>
          <li>Delta Dental Deluxe: $2,500 Orthodontic Benefit</li>
          <li>MRA: $1,500 Orthodontic Benefit</li>
        </ul>
        <p>
          For more information about your Mayo orthodontic benefit, here is a
          free report to help you maximize your orthodontic benefit.
        </p>
      </div>
    ),
  },
  {
    image: fivethingsInvisalign,
    buttonText: 'Get Your FREE Report',
    link: '/resources/free/top-five-things-you-need-to-know-about-invisalign',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Are you considering braces for yourself or your child?
        </p>
        <h2 css={matchPColor}>
          Top 5 Things You Need To Know About Invisalign
        </h2>
        <p>
          You’ve probably heard of invisalign®, the clear removable trays that
          many of our patients here in Rochester, MN use to straighten their
          teeth with minimal changes to their lifestyle. With the invisalign
          system, Dr. Collins can move your teeth gradually without the
          complications of metal brackets and wires.
        </p>
        <p>
          Although invisalign is a wonderful tool that orthodontists can use to
          straighten your teeth, there are several things you MUST KNOW prior to
          starting invisalign treatment. If you decide to start invisalign
          treatment without knowing these items in advance, your treatment
          outcome may not only end up less than ideal, but it could end up
          destructive to the health of your teeth and smile.
        </p>
      </div>
    ),
  },
]

class Page extends React.Component {
  render() {
    const freebieElements = freebies.map((item) => <Freebie {...item} />)
    return (
      <Layout location={this.props.location}>
        <div css={{ ...themeStyles.contentWidth }}>
          <PageTitle
            title="Free Reports and Articles"
            bannerKey={'RESOURCES'}
          />
          {freebieElements}
        </div>
      </Layout>
    )
  }
}

export default Page
